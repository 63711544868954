// src/LineChart.js
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Col, Container, Row, Form, Table, ProgressBar } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isWithinInterval } from 'date-fns';
import QuickMenu from '../../Components/QuickMenu';
import { Calender } from '../../Components/Forms';
import Header from '../../Components/Header';
import { useNavigate, Navigate } from 'react-router-dom';
import { FaFan } from "react-icons/fa";
import moment from 'moment';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DashBoard = () => {
  const initialData = [65, 59, 80, 81, 56, 55, 40, 70, 60, 75, 85, 90];
  const labels = ['April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', 'January', 'February', 'March'];

  const dateLabels = labels.map((label, index) => {
    const year = label === 'January' || label === 'February' || label === 'March' ? 2024 : 2023;
    return new Date(`${label} 1, ${year}`);
  });

  const [startDate, setStartDate] = useState(new Date('2023-04-01'));
  const [endDate, setEndDate] = useState(new Date('2024-03-31'));

  const getFilteredData = () => {
    return {
      labels: labels.filter((label, index) => {
        const date = dateLabels[index];
        return isWithinInterval(date, { start: startDate, end: endDate });
      }),
      data: initialData.filter((value, index) => {
        const date = dateLabels[index];
        return isWithinInterval(date, { start: startDate, end: endDate });
      }),
    };
  };

  const filtered = getFilteredData();

  const data = {
    labels: filtered.labels,
    datasets: [
      {
        label: 'Sales',
        data: filtered.data,
        fill: false,
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Sales Data',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  const [loading, setLoading] = useState(true);
  const [dgrdaily, setDgrdaily] = useState([]);
  const [dgrdailydate, setDgrdailydate] = useState([]);
  console.log("dgrdaily", dgrdaily)
  console.log("dgrdailydate", dgrdailydate)
  const fetchData = async () => {
    try {
      const customerId = localStorage.getItem("customerId");
      const response = await fetch(
        "https://api.srivarugreenenergy.com/customerapi/dashboard/daily.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: customerId
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const responseData = await response.json();
      setLoading(false);
      if (responseData.status === 200) {
        setDgrdaily(responseData.data);
        setDgrdailydate(responseData);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData(); // Call fetchData directly in useEffect
  }, []);
  const [dgrmonthly, setDgrmonthly] = useState([]);
  const [dgrmon, setDgrmon] = useState([]);
  console.log("dgrmon", dgrmon)
  console.log("dgrmonthly", dgrmonthly)
  const fetchDataMonth = async () => {
    try {
      const customerId = localStorage.getItem("customerId");
      const response = await fetch(
        "https://api.srivarugreenenergy.com/customerapi/dashboard/monthly.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: customerId
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const responseData = await response.json();
      setLoading(false);
      if (responseData.status === 200) {
        setDgrmonthly(responseData.data);
        setDgrmon(responseData);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchDataMonth(); // Call fetchData directly in useEffect
  }, []);
  const [dgryearly, setDgryearly] = useState([]);
  console.log("dgryearly", dgryearly)
  const fetchDataYear = async () => {
    try {
      const customerId = localStorage.getItem("customerId");
      const response = await fetch(
        "https://api.srivarugreenenergy.com/customerapi/dashboard/yearly.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: customerId
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const responseData = await response.json();
      setLoading(false);
      if (responseData.status === 200) {
        setDgryearly(responseData);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchDataYear(); // Call fetchData directly in useEffect
  }, []);

  const formatDate = (date) => {
    return moment(date).format('DD-MM-YYYY');
  };
  const formatNumber = (number) => {
    return number.toLocaleString();
  };
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem('session');
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error checking session:', error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);
  if (!isLoggedIn) {

    return <Navigate to="/login" replace />;
  }
  const totalProduction = dgrdaily.reduce((acc, data) => acc + (data.total_production || 0), 0);
  const totalProductionMonth = dgrmonthly.reduce((acc, data) => acc + (data.total_production || 0), 0);
  return (
    <>

      {/* <div id="main">
        <Header />
        <QuickMenu />
        <div className="bg-white my-3 rounded">
          <Container fluid>
            <Row>
              <Col lg='6'></Col>
              <Col lg='6' className='me-auto'>
                <Row className='d-flex justify-content-end'>
                  <Col lg='4'>
                    <div className='py-4'> <Calender labelname={"From "} /></div>
                  </Col>
                  <Col lg='4'>
                    <div className='py-4'> <Calender labelname={"To"} /></div>
                  </Col>
                </Row>
              </Col>
              <Col lg="12" className="py-3">
                <Line data={data} options={options} />
              </Col>
            </Row>
          </Container>
        </div>
      </div> */}
      <div id="main">
        <Header />
        <div className="bg-white my-3 rounded">
          <Container fluid>
            <Row>
              <Col lg='4' md='6' xs='12' className='py-3 '>
                <div className='counterturbine'>
                  <div className='text-end'>
                    <span className='m-4 fs-5 '>{formatDate(dgrdailydate.dg_date)}</span>
                  </div>
                  <div className='d-flex align-items-center m-2'>
                    <div className=''>
                      <span className='spin '><FaFan className='fafantur' /></span>
                    </div>
                    <div className='m-4'>
                      <span className='fs-5'>TotalProduction : {formatNumber(totalProduction || 0)}</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg='4' md='6' xs='12' className='py-3'>
                <div className='counterturbine'>
                  <div className='text-end'>
                    <span className='m-4  fs-5'>{dgrmon.current_month}</span>
                  </div>
                  <div className='d-flex align-items-center m-2'>
                    <div className=''>
                      <span className='spin '><FaFan /></span>
                    </div>
                    <div className='m-4'>
                      <span className='fs-5'>TotalProduction : {formatNumber(totalProductionMonth)}</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg='4' md='6' xs='12' className='py-3'>
                <div className='counterturbine'>
                  <div className='text-end'>
                    <span className='m-4 fs-5'>{dgryearly.current_year}</span>
                  </div>
                  <div className='d-flex align-items-center m-2'>
                    <div className=''>
                      <span className='spin '><FaFan /></span>
                    </div>
                    <div className='m-4'>
                      <span className='fs-5'>TotalProduction : {formatNumber(dgryearly.total_production || 0)}</span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <div className='m-3'>
            <Row>
              <Col lg={3} className='mb-5'>
                <div className='h5 text-center mb-2'>{formatDate(dgrdailydate.dg_date)}</div>
                <div style={{ overflow: "auto", maxHeight: "590px", maxWidth: "1880px" }}>
                  <Table striped bordered hover  >
                    <thead>
                      <tr>

                        <th >S.No</th>
                        <th >WEG No.</th>
                        <th >Loc. No</th>
                        <th >Total Production</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dgrdaily.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>{data.wtg_no}</td>
                          <td>{data.loc_no}</td>
                          <td>{formatNumber(data.total_production || "No data available")}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col lg={3}>
                <div className='h5 text-center mb-2'>{dgrmon.current_month}</div>
                <div style={{ overflow: "auto", maxHeight: "590px", maxWidth: "1880px" }}>
                  <Table striped bordered hover  >
                    <thead>
                      <tr>
                        <th >S.No</th>
                        <th >WEG No.</th>
                        <th >Loc. No</th>
                        <th >Total Production</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dgrmonthly.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.wtg_no}</td>
                          <td>{data.loc_no}</td>
                          <td>{formatNumber(data.total_production || "No data available")}</td>

                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col lg={3}>
                <div className='h5 text-center mb-2'>Machine Availability {formatDate(dgrdailydate.dg_date)}</div>
                <div style={{ overflow: "auto", maxHeight: "590px", maxWidth: "1880px" }}>
                  <Table striped bordered hover  >
                    <thead>
                      <tr>
                        <th >S.No</th>
                        <th >WEG No.</th>
                        <th >Loc. No</th>
                        <th >Machine Availability</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dgrdaily.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.wtg_no}</td>
                          <td>{data.loc_no}</td>
                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div style={{ width: '100%', marginRight: '10px' }}>
                                <ProgressBar
                                  now={parseFloat(data.machine_availability)}
                                  label={`${data.machine_availability}%`}
                                  variant="success"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col lg={3}>
                <div className='h5 text-center mb-2'>Grid Availability {formatDate(dgrdailydate.dg_date)}</div>
                <div style={{ overflow: "auto", maxHeight: "590px", maxWidth: "1880px" }}>
                  <Table striped bordered hover  >
                    <thead >
                      <tr>
                        <th >S.No</th>
                        <th >WEG No.</th>
                        <th >Loc. No</th>
                        <th >Grid Availability</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dgrdaily.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.wtg_no}</td>
                          <td>{data.loc_no}</td>

                          <td>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div style={{ width: '100%', marginRight: '10px' }}>
                                <ProgressBar
                                  now={parseFloat(data.grid_availability)}
                                  label={`${data.grid_availability}%`}
                                  variant="success"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>

  );
};

export default DashBoard;
