import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import { DropDownUI, TextForm } from "../../Components/Forms";
import { VscEyeClosed, VscEye } from "react-icons/vsc";


const Login = ({ onLogin }) => {
    const [user_id, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const handleLogin = async () => {
        try {
            if (!user_id || !password) {
                setError("Username and Password are required");
            }

            const loginData = {
                user_id: user_id,
                password: password,
            };

            const response = await fetch("https://api.srivarugreenenergy.com/customerapi/auth/login.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(loginData),
            });

            // if (!response.ok) {
            //   throw new Error('Login failed');
            // }

            const responseData = await response.json();

            console.log(responseData);

            if (responseData.status !== "Success") {
                setError(responseData.msg);
            }
            if (responseData.status === "Success") {
                localStorage.setItem("customerId", responseData.data.customer_unique_id);
                localStorage.setItem("customerName", responseData.data.customer_name);
                onLogin();
                navigate("/console/dashboard");
            }
        } catch (error) {
            console.error("Login error:", error.message);
            setError(error.message);
        }
    };
    return (
        <div className="login-picther">
            <Container
                fluid
                className="d-flex justify-content-center align-items-center min-vh-100 ssc"
            >
                <Row className="justify-content-center w-100">
                    <Col xs={10} sm={8} md={6} lg={3} className="kannan">
                        <div className="border p-4 shadow-sm rounded kannan1">
                            <div className="p-2 text-center">
                                <img
                                    src={require("../../assest/images/srivarulogo.png")}
                                    className="img-fluid login-logo"
                                    alt=""
                                />
                            </div>
                            <div className="text-center fs-3 py-2">
                                Signin to Your Account{" "}
                            </div>
                            <div className="py-3">
                                <TextForm
                                    labelname={'Customer User Name'}
                                    placeholder={"Customer User Name"}
                                    value={user_id}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                            </div>

                            <div className="py-3">
                                <TextForm
                                    labelname={'Password'}
                                    placeholder={"Password"}
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    suffix_icon={
                                        showPassword ? (
                                            <VscEye onClick={() => setShowPassword(false)} />
                                        ) : (
                                            <VscEyeClosed onClick={() => setShowPassword(true)} />
                                        )
                                    }
                                />
                            </div>
                            <div className='py-3 text-center'>
                                <Button onClick={handleLogin} className="btnlogin">Login</Button>
                            </div>
                            {error && (
                                <Alert variant="danger">{error}</Alert> // Render error alert banner if error state is not null
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    );
};

export default Login;




// import React, { useState } from 'react'
// import { Container, Row, Col, Form, Button } from 'react-bootstrap'
// import '../Login/login.css'
// import { useNavigate } from 'react-router-dom';
// import { DropDownUI, TextInputForm } from "../../Components/Forms";
// import { VscEyeClosed, VscEye } from "react-icons/vsc";
// const Login = () => {
//     const [password, setPassword] = useState("");
//     const [showPassword, setShowPassword] = useState(false);
//     const navigate = useNavigate();
//     const handleLogin = () => {

//         navigate("/dashboard");
//     };
//     return (
//         <div>
//             <Container className='pad'>
//                 <Row className='justify-content-center'>
//                     <Col lg="4" className='align-self-center'>
//                         <div className='login-box'>
//                             <div className='text-center'>
//                                 <div> Welcome Sign in Your Credential</div>
//                             </div>
//                             <div className='py-2'>
//                                 <Form.Group className="mb-3" >
//                                     <Form.Label>Email address</Form.Label>
//                                     <Form.Control type="email" placeholder="Enter Your Mail" />
//                                 </Form.Group>
//                             </div>
//                             <div className="py-3">
//                                 <TextInputForm
//                                     labelname={'Password'}
//                                     placeholder={"Password"}
//                                     type={showPassword ? "text" : "password"}
//                                     value={password}
//                                     onChange={(e) => setPassword(e.target.value)}
//                                     suffix_icon={
//                                         showPassword ? (
//                                             <VscEye onClick={() => setShowPassword(false)} />
//                                         ) : (
//                                             <VscEyeClosed onClick={() => setShowPassword(true)} />
//                                         )
//                                     }
//                                 />
//                             </div>
//                             <div className='py-2 text-center'>
//                                 <Button className='create-btn' onClick={handleLogin}>Login</Button>
//                             </div>
//                         </div>
//                     </Col>
//                 </Row>
//             </Container>
//         </div>
//     )
// }

// export default Login

