import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Button, Table } from 'react-bootstrap'
import TableUI from '../../Components/Table'
import { TextInputForm, Calender, DropDownUI } from '../../Components/Forms'
import Header from '../../Components/Header'
import Select from 'react-select';
import moment from "moment/moment";
import * as XLSX from "xlsx";
import { useNavigate, Navigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
const types = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
];
const DGR = () => {
  const [formDatarep, setFormDatarep] = useState({
    customer_name: [],
    wtg_no: [],
    report: "",
    from_date: "",
    to_date: "",
  });
  console.log("formDatarep", formDatarep);
  const setLabel = (value, field) => {
    setFormDatarep({
      ...formDatarep,
      [field]: moment(value).format("YYYY-MM-DD"), // Update the specified field in formData with the provided value
    });
  };
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    setFormDatarep({
      ...formDatarep,
      [fieldName]: value,
    });
  };
  const [loading, setLoading] = useState(true);
  const [customerData, setCustomerData] = useState([]);
  console.log("customerData", customerData);
  const [turbineOptions, setTurbineOptions] = useState([]);
  const fetchData = async () => {
    try {
      const customerId = localStorage.getItem("customerId");
      const response = await fetch(
        "https://api.srivarugreenenergy.com/customerapi/customerlist/list.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: customerId
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const responseData = await response.json();
      setLoading(false);
      if (responseData.status === 200) {
        setCustomerData(responseData.data);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData(); // Call fetchData directly in useEffect
  }, []);

  const handleSelectChange = (selectedOptions) => {
    const selectedCustomerIds = selectedOptions.map(option => option.value);
    setFormDatarep(prev => ({
      ...prev,
      customer_name: selectedCustomerIds,
      wtg_no: [] // Clear turbine numbers when customer changes
    }));
    updateTurbineOptions(selectedCustomerIds);
  };

  const updateTurbineOptions = (selectedCustomerIds) => {
    const selectedCustomers = customerData.filter(customer => selectedCustomerIds.includes(customer.customer_unique_id));
    const newTurbineNumbers = getTurbineNumbers(selectedCustomers);

    setTurbineOptions(newTurbineNumbers.map(turbineNo => ({
      label: turbineNo,
      value: turbineNo,
    })));
  };

  const getTurbineNumbers = (customers) => {
    let turbineNumbers = [];

    customers.forEach(customer => {
      if (Array.isArray(customer.wtg_no)) {
        turbineNumbers.push(...customer.wtg_no);
      } else if (typeof customer.wtg_no === 'string') {
        turbineNumbers.push(...customer.wtg_no.split(','));
      } else if (customer.wtg_no) {
        turbineNumbers.push(customer.wtg_no.toString());
      }
    });

    return [...new Set(turbineNumbers)].map(turbineNo => turbineNo.toString().trim());
  };

  const customerOptions = customerData.map(customer => ({
    label: customer.customer_name,
    value: customer.customer_unique_id,
  }));


  const [userData, setUserData] = useState([]);
  console.log("userData", userData);
  const fetchDataDGR = async () => {
    setLoading(true);
    try {
      const customerId = localStorage.getItem("customerId");
      const response = await fetch("https://api.srivarugreenenergy.com/customerapi/dgr_report/list.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: customerId,
            customer_unique_id: formDatarep.customer_name,
            wtg_no: formDatarep.wtg_no,
            report: formDatarep.report,
            from_date: formDatarep.from_date,
            to_date: formDatarep.to_date,
          }),
        }
      );
      console.log(
        "api",
        JSON.stringify({
          user_id: customerId,
          customer_unique_id: formDatarep.customer_name,
          wtg_no: formDatarep.wtg_no,
          report: formDatarep.report,
          from_date: formDatarep.from_date,
          to_date: formDatarep.to_date,
        })
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      if (responseData.status === 200) {
        setUserData(responseData.data);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      console.error("Error fetching report data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchDataDGR();
  };
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem('session');
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error checking session:', error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);
  if (!isLoggedIn) {

    return <Navigate to="/login" replace />;
  }
  const downloadExcel = () => {
    const formattedData = userData.map((data, index) => {
      if (formDatarep.report === "daily") {
        return {
          "S No": index + 1,
          Date: moment(data.dg_date).format('DD-MM-YYYY'),
          "Customer Name": data.customer_name,
          "Turbine No": data.wtg_no,
          "Location No": data.loc_no,
          "HTSC No": data.htsc_no,
          Site: data.site_name,
          // "Gen 0": data.gen_zero,
          // "Gen 1": data.gen_one,
          // "Gen 2": data.gen_two,
          "Total Production": data.total_production || 0,
          "KWH Import EB": data.kwh_imp || 0,
          "KWH Export EB": data.kwh_exp || 0,
          "Kvarh Imp EB": data.kvarh_imp || 0,
          "Kvarh Exp EB": data.kvarh_exp || 0,
          "Net Exp": data.net_exp || 0,
          "Gen 1 hrs": data.gen_onehrs || 0,
          "Gen 2 hrs": data.gen_twohrs || 0,
          "Gen Hrs": data.gen_hourtotal || 0,
          "M/c Fault hrs": data.error_overtotal || "00:00",
          "M/c Maintenance hrs": data.maintenance_overtotal || "00:00",
          "Grid fault hrs": data.gridfault_overtotal || "00:00",
          "Grid down hrs": data.griddrop_overtotal || "00:00",
          "Lull hrs": data.lull_hrs || 0,
          "Machine Availability %": data.machine_availability || 0,
          "Grid Availability %": data.grid_availability || 0,
          "Kvarh Imp %xp": data.kvarh_imp_per || 0,
          "Generation @100 % Grid": data.generation_grid || 0,

        };
      } else if (formDatarep.report === "monthly") {
        return {
          "S No": index + 1,
          Date: data.month,
          "Customer Name": data.customer_name,
          "Turbine No": data.wtg_no,
          "Location No": data.loc_no,
          "HTSC No": data.htsc_no,
          Site: data.site_name,
          // "Gen 0": data.gen_zero,
          // "Gen 1": data.gen_one,
          // "Gen 2": data.gen_two,
          "Total Production": data.total_production || 0,
          "KWH Import EB": data.kwh_imp || 0,
          "KWH Export EB": data.kwh_exp || 0,
          "Kvarh Imp EB": data.kvarh_imp || 0,
          "Kvarh Exp EB": data.kvarh_exp || 0,
          "Net Exp": data.net_exp || 0,
          "Gen 1 hrs": data.gen_onehrs || 0,
          "Gen 2 hrs": data.gen_twohrs || 0,
          "Gen Hrs": data.gen_hourtotal || 0,
          "M/c Fault hrs": data.error_overtotal || "00:00",
          "M/c Maintenance hrs": data.maintenance_overtotal || "00:00",
          "Grid fault hrs": data.gridfault_overtotal || "00:00",
          "Grid down hrs": data.griddrop_overtotal || "00:00",
          "Lull hrs": data.lull_hrs || 0,
          "Machine Availability %": data.machine_availability || 0,
          "Grid Availability %": data.grid_availability || 0,
          "Kvarh Imp %xp": data.kvarh_imp_per || 0,
          "Generation @100 % Grid": data.generation_grid || 0,


        };
      }
      else if (formDatarep.report === "yearly") {
        return {
          "S No": index + 1,
          Date: data.year,
          "Customer Name": data.customer_name,
          "Turbine No": data.wtg_no,
          "Location No": data.loc_no,
          "HTSC No": data.htsc_no,
          Site: data.site_name,
          // "Gen 0": data.gen_zero,
          // "Gen 1": data.gen_one,
          // "Gen 2": data.gen_two,
          "Total Production": data.total_production || 0,
          "KWH Import EB": data.kwh_imp || 0,
          "KWH Export EB": data.kwh_exp || 0,
          "Kvarh Imp EB": data.kvarh_imp || 0,
          "Kvarh Exp EB": data.kvarh_exp || 0,
          "Net Exp": data.net_exp || 0,
          "Gen 1 hrs": data.gen_onehrs || 0,
          "Gen 2 hrs": data.gen_twohrs || 0,
          "Gen Hrs": data.gen_hourtotal || 0,
          "M/c Fault hrs": data.error_overtotal || "00:00",
          "M/c Maintenance hrs": data.maintenance_overtotal || "00:00",
          "Grid fault hrs": data.gridfault_overtotal || "00:00",
          "Grid down hrs": data.griddrop_overtotal || "00:00",
          "Lull hrs": data.lull_hrs || 0,
          "Machine Availability %": data.machine_availability || 0,
          "Grid Availability %": data.grid_availability || 0,
          "Kvarh Imp %xp": data.kvarh_imp_per || 0,
          "Generation @100 % Grid": data.generation_grid || 0,
        };
      }
    });
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Report");
    XLSX.writeFile(wb, "report.xlsx");
  };
  const renderTable = () => {
    if (formDatarep.report === "daily") {
      return (
        <div style={{ overflow: "auto", maxHeight: "640px", maxWidth: "1810px" }}>
          <Table striped bordered hover  >
            <thead>
              <tr>

                <th >S.No</th>
                <th >Date</th>
                <th >Customer Name</th>
                <th >WEG No.</th>
                <th >Loc. No</th>
                <th >HTSC No. </th>
                <th >Site </th>
                {/* <th >Gen 0</th>
                <th >Gen 1</th>
                <th >Gen 2</th> */}
                <th >Total Production</th>
                <th >KWH Import EB</th>
                <th >KWH Export EB</th>
                <th >Kvarh Imp EB</th>
                <th >Kvarh exp EB</th>
                <th >Net Exp</th>
                <th >Gen 1 hrs</th>
                <th >Gen 2 hrs</th>
                <th >Gen Hrs</th>
                <th >M/c Fault hrs</th>
                <th >M/c Maintenance hrs</th>
                <th >Grid fault hrs</th>
                <th >Grid down hrs</th>
                <th >Lull hrs</th>
                <th >Machine Availability %</th>
                <th >Grid Availability %</th>
                <th >Kvarh Imp %xp</th>
                <th >Generation @100 % Grid</th>

              </tr>
            </thead>
            <tbody>
              {userData.map((data, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{moment(data.dg_date).format('DD-MM-YYYY')}</td>
                  <td>{data.customer_name}</td>
                  <td>{data.wtg_no}</td>
                  <td>{data.loc_no}</td>
                  <td>{data.htsc_no}</td>
                  <td>{data.site_name}</td>
                  {/* <td>{data.gen_zero}</td>
                  <td>{data.gen_one}</td>
                  <td>{data.gen_two}</td> */}
                  <td>{data.total_production}</td>
                  <td>{data.kwh_imp || 0}</td>
                  <td>{data.kwh_exp || 0}</td>
                  <td>{data.kvarh_imp || 0}</td>
                  <td>{data.kvarh_exp || 0}</td>
                  <td>{data.net_exp || 0}</td>
                  <td>{data.gen_onehrs || 0}</td>
                  <td>{data.gen_twohrs || 0}</td>
                  <td>{data.gen_hourtotal || 0}</td>
                  <td>{data.error_overtotal || "00:00"} </td>
                  <td>{data.maintenance_overtotal || "00:00"}</td>
                  <td>{data.gridfault_overtotal || "00:00"}</td>
                  <td>{data.griddrop_overtotal || "00:00"}</td>
                  <td>{data.lull_hrs || 0}</td>
                  <td>{data.machine_availability || 0}</td>
                  <td>{data.grid_availability || 0}</td>
                  <td>{data.kvarh_imp_per || 0}</td>
                  <td>{data.generation_grid || 0}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    } else if (formDatarep.report === "monthly") {
      return (
        <div style={{ overflow: "auto", maxHeight: "640px", maxWidth: "1810px" }}>
          <Table striped bordered hover  >
            <thead>
              <tr>

                <th >S.No</th>
                <th >Month</th>
                <th >Customer Name</th>
                <th >WEG No.</th>
                <th >Loc. No</th>
                <th >HTSC No. </th>
                <th >Site </th>
                {/* <th >Gen 0</th>
                <th >Gen 1</th>
                <th >Gen 2</th> */}
                <th >Total Production</th>
                <th >KWH Import EB</th>
                <th >KWH Export EB</th>
                <th >Kvarh Imp EB</th>
                <th >Kvarh exp EB</th>
                <th >Net Exp</th>
                <th >Gen 1 hrs</th>
                <th >Gen 2 hrs</th>
                <th >Gen Hrs</th>
                <th >M/c Fault hrs</th>
                <th >M/c Maintenance hrs</th>
                <th >Grid fault hrs</th>
                <th >Grid down hrs</th>
                <th >Lull hrs</th>
                <th >Machine Availability %</th>
                <th >Grid Availability %</th>
                <th >Kvarh Imp %xp</th>
                <th >Generation @100 % Grid</th>


              </tr>
            </thead>
            <tbody>
              {userData.map((data, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{data.month}</td>
                  <td>{data.customer_name}</td>
                  <td>{data.wtg_no}</td>
                  <td>{data.loc_no}</td>
                  <td>{data.htsc_no}</td>
                  <td>{data.site_name}</td>
                  {/* <td>{data.gen_zero}</td>
                  <td>{data.gen_one}</td>
                  <td>{data.gen_two}</td> */}
                  <td>{data.total_production}</td>
                  <td>{data.kwh_imp || 0}</td>
                  <td>{data.kwh_exp || 0}</td>
                  <td>{data.kvarh_imp || 0}</td>
                  <td>{data.kvarh_exp || 0}</td>
                  <td>{data.net_exp || 0}</td>
                  <td>{data.gen_onehrs || "00:00"}</td>
                  <td>{data.gen_twohrs || "00:00"}</td>
                  <td>{data.gen_hourtotal || "00:00"}</td>
                  <td>{data.error_overtotal || "00:00"} </td>
                  <td>{data.maintenance_overtotal || "00:00"}</td>
                  <td>{data.gridfault_overtotal || "00:00"}</td>
                  <td>{data.griddrop_overtotal || "00:00"}</td>
                  <td>{data.lull_hrs || 0}</td>
                  <td>{data.machine_availability || 0}</td>
                  <td>{data.grid_availability || 0}</td>
                  <td>{data.kvarh_imp_per || 0}</td>
                  <td>{data.generation_grid || 0}</td>


                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    } else if (formDatarep.report === "yearly") {
      return (
        <div style={{ overflow: "auto", maxHeight: "640px", maxWidth: "1810px" }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Year</th>
                <th>Customer Name</th>
                <th>WEG No.</th>
                <th>Loc. No</th>
                <th>HTSC No.</th>
                <th>Site</th>
                {/* <th>Gen 0</th>
                <th>Gen 1</th>
                <th>Gen 2</th> */}
                <th>Total Production</th>
                <th>KWH Import EB</th>
                <th>KWH Export EB</th>
                <th>Kvarh Imp EB</th>
                <th>Kvarh exp EB</th>
                <th>Net Exp</th>
                <th>Gen 1 hrs</th>
                <th>Gen 2 hrs</th>
                <th>Gen Hrs</th>
                <th>M/c Fault hrs</th>
                <th>M/c Maintenance hrs</th>
                <th>Grid fault hrs</th>
                <th>Grid down hrs</th>
                <th>Lull hrs</th>
                <th>Machine Availability %</th>
                <th>Grid Availability %</th>
                <th>Kvarh Imp %xp</th>
                <th>Generation @100 % Grid</th>

              </tr>
            </thead>
            <tbody>
              {userData.map((data, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{data.year}</td>
                  <td>{data.customer_name}</td>
                  <td>{data.wtg_no}</td>
                  <td>{data.loc_no}</td>
                  <td>{data.htsc_no}</td>
                  <td>{data.site_name}</td>
                  {/* <td>{data.gen_zero}</td>
                  <td>{data.gen_one}</td>
                  <td>{data.gen_two}</td> */}
                  <td>{data.total_production}</td>
                  <td>{data.kwh_imp || 0}</td>
                  <td>{data.kwh_exp || 0}</td>
                  <td>{data.kvarh_imp || 0}</td>
                  <td>{data.kvarh_exp || 0}</td>
                  <td>{data.net_exp || 0}</td>
                  <td>{data.gen_onehrs || 0}</td>
                  <td>{data.gen_twohrs || 0}</td>
                  <td>{data.gen_hourtotal || 0}</td>
                  <td>{data.error_overtotal || "00:00"} </td>
                  <td>{data.maintenance_overtotal || "00:00"}</td>
                  <td>{data.gridfault_overtotal || "00:00"}</td>
                  <td>{data.griddrop_overtotal || "00:00"}</td>
                  <td>{data.lull_hrs || 0}</td>
                  <td>{data.machine_availability || 0}</td>
                  <td>{data.grid_availability || 0}</td>
                  <td>{data.kvarh_imp_per || 0}</td>
                  <td>{data.generation_grid || 0}</td>


                </tr>
              ))}
            </tbody>
          </Table>
        </div >
      );
    }
    else {
      return null;
    }
  };
  return (

    <div id='main'>
      <Header />
      <Container fluid className='main my-3'>
        <Row>

          <Col lg="3" md="4" xs="12" className="py-3">
            <div className='d-flex'>
              <div className="w-100 m-1 ">
                <Calender
                  setLabel={(date) => setLabel(date, "from_date")}
                  selectedDate={formDatarep.from_date}
                  calenderlabel="FromDate"
                />
              </div>
              <div className="w-100 m-1 ">
                <Calender
                  setLabel={(date) => setLabel(date, "to_date")}
                  selectedDate={formDatarep.to_date}
                  calenderlabel="ToDate"
                />
              </div>
            </div>
          </Col>
          {/* <Col lg="3" md="4" xs="12" className="py-3">
            <div className="w-100">
              <Calender
                setLabel={(date) => setLabel(date, "to_date")}
                selectedDate={formDatarep.to_date}
                calenderlabel="ToDate"
              />
            </div>
          </Col> */}
          <Col lg="3" md="4" xs="12" className="py-3">
            <span>
              <label className="mb-2">CustomerName</label>
            </span>
            <div>
              <Select
                isMulti
                options={customerOptions}
                placeholder="Select customers"
                onChange={handleSelectChange}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: '#e5e8f1',
                    border: "1px solid #b8c8f8"
                  }),
                  input: (provided) => ({
                    ...provided,
                    backgroundColor: '#e5e8f1',

                  })
                }}
              />
            </div>
          </Col>
          <Col lg="3" md="4" xs="12" className="py-3">
            <span>
              <label className="mb-2">Turbine No</label>
            </span>
            <div >
              <Select

                isMulti
                options={turbineOptions}
                placeholder="Select turbines"
                onChange={(selectedOptions) =>
                  setFormDatarep({
                    ...formDatarep,
                    wtg_no: selectedOptions.map((option) => option.value),
                  })
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: '#e5e8f1',
                    border: "1px solid #b8c8f8"
                  }),
                  input: (provided) => ({
                    ...provided,
                    backgroundColor: '#e5e8f1',

                  })
                }}
              />
            </div>
          </Col>
          <Col lg="3" md="4" xs="12" className="py-3">
            <DropDownUI
              optionlist={types}
              placeholder="Report"
              labelname="Report"
              name="report"
              value={formDatarep.report}
              onChange={(updatedFormData) =>
                setFormDatarep({
                  ...formDatarep,
                  report: updatedFormData.report,
                })
              }

            ></DropDownUI>
          </Col>
          <Col lg="9" md="12" xs="12" className="py-3 ">
            <div className="mt-3"></div>
            <div className="d-flex justify-content-end">
              <span className="mx-2">
                <Button className='btn-down'
                  label={<>Submit</>} onClick={handleSubmit}
                >Submit</Button>
              </span>
              <span className="mx-2">
                <Button className='btn-down'
                  label={<>Download</>} onClick={downloadExcel}
                >Download Excel</Button>
              </span>
            </div>
          </Col>
          {/* <Col className='py-3'>
            <div className='wrdbrk overflow'>
              <TableUI headers={TableHead} body={BrandsData} />
            </div>
          </Col> */}
          <Col lg="4" md="12" xs="12" className="py-3 "></Col>
          <Col lg="12">
            {loading ? <Spinner animation="border" /> : renderTable()}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default DGR